<template>
  <base-view title="Auxiliar de Movimientos de Cuentas">
    <div class="container-fluid mt-4">
      <div class="card">
        <div class="card-body">
          <reporte-auxiliar-cuentas/>
        </div>
      </div>
    </div>
  </base-view>
</template>

<script>
import ReporteAuxiliarCuentas from '@/components/Reportes/ReporteAuxiliarCuentas'

export default {
  name: 'ReporteAuxiliarCuentasView',

  components: {
    ReporteAuxiliarCuentas
  }
}
</script>
